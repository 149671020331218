main{
    min-height: 80vh;
}

h1{
    font-size: 1.8rem;
    padding: 1rem 0;
}


h1{
    font-size: 1.4rem;
    padding: 0.5rem 0;
}

.rating span{
    margin:0.1em;
}

h3 {
    padding: 1rem 0;
}

a{
    text-decoration: none;
}